:root {
  --input-col: #F8C184;
  --body-col: #161616;
  --body-light-col: #272727;
  --header-col: #272727;
  --body-contrast-col: #FFF;
  --header-contrast-col: #FFF;
  --brand-col: #f39124;

  --border-radius: .5rem;
}