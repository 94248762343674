.header {
  background-color: var(--header-col);
  color: var(--header-contrast-col);
  padding: 1rem 0;

  .container {
    display: flex;
    justify-content: space-between;

    img {
      height: 40px;
    }
  }

  .brand {
    display: flex;
    flex-direction: row;

    .version {
      padding: 10px;
      text-align: center;
      color: white;
      font-size: small;
    }
  }
}

.mainContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;

  .mainWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    max-height: calc(100vh - 72px);

    .aside {
      height: 100%;
      min-width: 350px;
      display: flex;
      background-color: var(--body-light-col);
      flex-direction: column;

      .navItem {
        display: flex;
        color: var(--body-contrast-col);
        height: 100%;

        .icons {
          & > a {
            transition: all .2s;
            background-color: var(--body-light-col);
            display: flex;
            flex-direction: column;
            min-width: 90px;
            height: 80px;
            padding: .5rem;
            justify-content: center;
            align-items: center;
            border-left: 2px solid transparent;
            font-size: .85rem;
            color: inherit;
            border-right: 1px solid rgba(255, 255, 255, .2);

            & > svg {
              width: 40px;
              height: 40px;
            }

            &:hover,
            &:global(.active) {
              border-left: 2px solid var(--brand-col);
              background-color: var(--body-col);
              border-right-color: transparent;
            }

            &:hover {
              border-left-width: 10px;
            }

          }

          & > a {
            color: inherit;
            padding: .75rem 0.5rem;

            &:hover {
              background-color: var(--body-light-col);
              text-decoration: none;
            }

            &:global(.active) {
              color: var(--brand-col);
            }
          }
        }

        .items {
          width: 100%;
          background-color: var(--body-col);
          flex-direction: column;
          font-size: .85rem;
          display: flex;
          padding: .75rem .5rem .75rem 1rem;
          background-color: var(--body-col);
          border-right-color: transparent;

          .title {
            font-weight: bold;
            font-size: 1.25rem;
            color: #888;
            margin-bottom: 1rem;
          }

          &>a {
            color: inherit;
            padding: .75rem 0.5rem;

            &:hover {
              background-color: var(--body-light-col);
              text-decoration: none;
            }

            &:global(.active) {
              color: var(--brand-col);
            }
          }
        }
      }
    }

    .main {
      width: 100%;
      height: 100%;
      padding: 2rem 1rem;
      overflow: auto;
    }

    @keyframes slideInLeft {
      0% {
        transform: translate3d(-200px, 0, 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
