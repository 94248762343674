.loginWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--body-col);
  flex-direction: column;

  .formWrapper {
    display: flex;
    background-color: var(--body-contrast-col);
    flex-direction: column;
    padding: 2rem;
    width: 400px;
    max-width: 95vw;
    border-radius: var(--border-radius);

    .imgWrapper {
      text-align: center;
    }

    form {
      margin-top: 2rem;

      :global(.MuiFormControl-root) {
        margin-bottom: 1rem;
      }
    }
  }

  .version {
    padding: 10px;
    text-align: center;
    width: 100%;
    color: white;
    font-size: small;
  }
}
