@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./variables.scss");

html {
  font-size: 16px;
}

body {
  font-family: Roboto, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}
.ql-tooltip {
  left: 0!important;
  top: 0!important;
}
a {
  text-decoration: none;
  transition: all .2s;
}

a:hover {
  text-decoration: underline;
}